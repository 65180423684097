export enum DASHBOARD_QUERY_PARAMS {
  SEARCH = 'search',
  TAB = 'tab',
  // TODO: Add more custom query for dashboard
}

export enum DASHBOARD_TABS {
  ALL = 'all',
  AS_EMPLOYEE = 'as_employee',
  AS_COACH = 'as_coach',
}
