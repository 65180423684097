import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Title = styled.span`
  color: ${COLORS.TEXT_HOVER};
  font-size: 24px;
  text-align: center;
  letter-spacing: -0.27px;
`;

export const Subtitle = styled.span`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
  letter-spacing: -0.16px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: row;
  border-top: solid 1px rgba(151, 151, 151, 0.1);
  padding-top: 24px;
`;

export const Image = styled.img<{ height: number; width: number }>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  margin: 54px 0;
  align-self: center;
`;

export const Card = styled.div`
  margin-top: 116px;
  display: flex;
  flex-direction: column;
  width: 500px;
  box-sizing: border-box;
  padding: 27px 32px 24px 32px;
  border-radius: 10px;
  background-color: ${COLORS.WHITE};
  align-items: start;
`;
