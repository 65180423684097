import { useHistory } from 'react-router';

import routes from '~/constants/routes';
import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import { getReviewTask, getReviewTaskOutside } from '~/services/tasks';

import type { IPopulatedReviewTask, IPopulatedUserReview } from './types';
import type { IUser, WithReplace } from '@learned/types';

const useReviewTask = ({ reviewTaskId }: { reviewTaskId: string }) => {
  const history = useHistory();

  const [{ reviewTask, userReview }, isLoading] = useAsyncFetch<{
    reviewTask?: IPopulatedReviewTask;
    userReview?: IPopulatedUserReview;
  }>({
    fetch: async () => {
      const response = await getReviewTask(reviewTaskId).catch((error) => {
        if (error.response && (error.response.status === 403 || error.response.status === 404)) {
          history.replace(routes.ERROR + '?errorCode=404');
          return;
        }
        history.replace(routes.ERROR);
      });

      return {
        reviewTask: response.data.task,
        userReview: response.data.userReview,
      };
    },
    initialState: {},
  });

  return { reviewTask, userReview, isLoading };
};

const useReviewTaskOutside = ({ token }: { token: string }) => {
  const [{ reviewTask, userReview }, isLoading, refetch] = useAsyncFetch<{
    reviewTask?: WithReplace<IPopulatedReviewTask, { userFrom: IUser }>;
    userReview?: IPopulatedUserReview;
  }>({
    fetch: async () => {
      const response = await getReviewTaskOutside(token);

      return {
        reviewTask: response.data.task,
        userReview: response.data.userReview,
      };
    },
    initialState: {},
  });

  return { reviewTask, userReview, isLoading, refetch };
};

export { useReviewTask, useReviewTaskOutside };
