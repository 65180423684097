import { REVIEW_QUESTION_EVALUATORS, TASK_STATUS } from '@learned/constants';
import {
  ICalendarEvent,
  IFile,
  ILanguageValue,
  IReviewRating,
  IRichText,
  ITask,
  IUser,
  IUserReview,
} from '@learned/types';

export interface IReviewDashboardUserForm {
  name: ILanguageValue[];
  description?: ILanguageValue[] | null;
  status: IUserReview['status'];
  privacy: IUserReview['privacy'];
  tasksSelf: ITask[];
  tasksReceivedPeers: ITask[];
  tasksReceivedCoaches: ITask[];
  taskNominatePeers: ITask | null;
  tasksProvidedPeers: ITask[];
  tasksProvidedCoaches: ITask[];
  employee: IUser; // from userReview.createdFor
  guests: IUserReview['guests'];
  coaches: IUserReview['coaches'];
  signatures: IUserReview['signatures'];
  settings: IUserReview['settings'];
  dateOfConversation: IUserReview['dateOfConversation'];
  dateOfConversationEnd: IUserReview['dateOfConversationEnd'];
  attachments: IFile[];
  evaluators: REVIEW_QUESTION_EVALUATORS[];
  calendarEvent: ICalendarEvent;
  placeholderStatus?: TASK_STATUS;
  // TODO
}

export interface IReviewNextStep {
  comment: string;
  createdFor: IUser['id'];
  createdBy: IUser['id'];
  id: string;
  isCompleted: boolean;
}

export interface IReviewSummaryForm {
  content: IRichText;
}

export interface IReviewNextStepsForm {
  items: {
    // TODO: next mr
  };
}

export interface INextStepEditorForm {
  comment: string;
  participant: IUser['id'];
}
export interface ISignatureForm {
  signature: string;
  comment: string;
  isAgree: boolean;
  isSeen: boolean;
}

export enum RATING_USER_TYPE {
  SELF = 'Self',
  COACH = 'Coach',
  PEER = 'Peer',
  EXTERNAL_PEER = 'External peer',
}

export type AnswerType = IReviewRating & { id?: string; reviewType: string; isOpened: boolean };

export interface IAnswer {
  questionId: string;
  answer?: string | number | null;
  oldAnswer?: string | number | null;
  comment?: string | null;
  isNotApplicable?: boolean;
}
