import React, { useCallback, useMemo, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, TASK_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import RocketIcon from '~/components/Icons/Rocket';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useQueryURL } from '~/hooks/useQueryURL';
import { getCompanyOutside } from '~/services/companies';
import { changeTaskStatusOutside } from '~/services/tasks';
import { getMultiLangString } from '~/utils/getMultiLangString';

import { GiveReview } from '../components/GiveReview';
import { InputNoLongerPossible } from '../components/InputNoLongerPossible';
import { OutsideDeclined } from '../components/OutsideDeclined';
import { useReviewTaskOutside } from '../hooks';

import type { ILanguage } from '@learned/types';

const DEFAULT_LANG = {
  language: 'English',
  country: 'Great Britain',
  locale: 'en_GB',
};

function OutsideReview() {
  const { i18n } = useLingui();
  const { values: queryParams } = useQueryURL({ keys: ['token'] });
  const token = queryParams.token;
  const introTitle = i18n._(t`Provide input`);

  const { reviewTask, userReview, isLoading, refetch } = useReviewTaskOutside({ token });

  const [company] = useAsyncFetch(
    {
      fetch: async () => {
        if (!reviewTask?.company) {
          return undefined;
        }

        const company: { logoUrl: string; primaryLang: ILanguage } = await getCompanyOutside(
          reviewTask?.company,
        );

        return company;
      },
      initialState: { primaryLang: DEFAULT_LANG, logoUrl: '' },
    },
    [reviewTask],
  );

  const [languages, setLanguages] = useState<ILanguage[]>(company ? [company.primaryLang] : []);

  const languageState: ILanguageStateReturn | undefined = useMemo(() => {
    if (!(company && company.primaryLang)) {
      return undefined;
    }

    return {
      languages,
      setLanguages,
      companyLanguages: [company.primaryLang],
      companyPrimaryLanguage: company.primaryLang,
    };
  }, [company, languages, setLanguages]);

  const useExternalMultiLangString = () => {
    const currentConnection = { preferredLang: company?.primaryLang };
    const currentCompany = { languages: [company!.primaryLang], primaryLang: company!.primaryLang };

    return useCallback(
      (multiLangString: Record<string, string> | string) => {
        return multiLangString
          ? getMultiLangString(multiLangString, currentConnection ?? {}, currentCompany, false)
          : '';
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [company],
    );
  };

  const onDecline = async () => {
    if (
      reviewTask &&
      (await confirm({
        title: i18n._(t`Decline request?`),
        description: i18n._(
          t`Are you sure you want to decline this request? This action cannot be undone.`,
        ),
        type: CONFIRMATION_MODAL_TYPE.WARNING,
      }))
    ) {
      await changeTaskStatusOutside(TASK_STATUS.DECLINED, token);
      await refetch();
    }
  };

  return (
    <ShowSpinnerIfLoading loading={isLoading}>
      {reviewTask && userReview && company && (
        <GiveReview
          key={reviewTask.id}
          reviewTask={{ ...reviewTask, userFrom: reviewTask.userFrom.id }}
          userReview={userReview}
          introTitle={introTitle}
          companyLogo={company.logoUrl}
          languageState={languageState!}
          userFrom={reviewTask.userFrom.id}
          useMultiLangString={useExternalMultiLangString}
          DeclinedScreen={OutsideDeclined}
          InputNoLongerPossibleScreen={
            <InputNoLongerPossible status={reviewTask.status} isActionButton={false} />
          }
          onDecline={onDecline}
          userFromObject={reviewTask.userFrom}
        />
      )}
      {(!reviewTask || !userReview) && (
        <Placeholder title={i18n._(t`No review task`)} Icon={RocketIcon} />
      )}
    </ShowSpinnerIfLoading>
  );
}

export { OutsideReview };
