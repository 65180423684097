import React, { useEffect, useState } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  ProductName,
  ROLES,
  TASK_FILTER_BY_USER_TYPE,
  DASHBOARD_TABS,
  DASHBOARD_QUERY_PARAMS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import FirstTimeSetupModalNew from '~/components/FirstTimeSetupModalNew';
import { HeaderTabs } from '~/components/HeaderTabs';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import BaseLayout from '~/layouts/BaseLayout';

import { Header } from './components/Header';
import { TasksSection } from './components/TasksSection';
import { UserDashboardSidebar } from './components/UserDashboardSidebar';
import {
  DashboardWrapper,
  HeaderWrapper,
  LeftColumn,
  RightColumn,
  SectionWrapper,
  Title,
  Wrapper,
} from './design';

import routes from '~/constants/routes';
import { useQueryURL } from '~/hooks/useQueryURL';
import {
  checkModuleConversations,
  checkModuleJobMatrix,
  checkModuleReviews,
  getCurrentCompanyId,
  getUser,
} from '~/selectors/baseGetters';
import { updateTrialEndedProductsMessages } from '~/services/users';
import { getCompanyConnection } from '~/utils/getCompanyConnection';
import { getProductName } from '~/utils/products';

import type { I18n } from '@lingui/core';

interface TrialEndedProduct {
  dateTime: string;
  read: boolean;
}

const UserDashboard = () => {
  const { i18n } = useLingui();
  const history = useHistory();
  const user = useSelector(getUser);
  const companyId = useSelector(getCurrentCompanyId);
  const companyConnection = useSelector(() => getCompanyConnection(companyId, user));
  const isModuleJobMatrixEnabled = useSelector(checkModuleJobMatrix);
  const isModuleReviewsEnabled = useSelector(checkModuleReviews);
  const isModuleMeetingsEnabled = useSelector(checkModuleConversations);
  const [isTrialEndedModalVisible, setIsTrialEndedModalVisible] = useState(false);
  const [trialEndedProduct, setTrialEndedProduct] = useState<{
    name: ProductName;
    dateTime: string;
  }>();

  const customQueryParams = useQueryURL({ keys: [DASHBOARD_QUERY_PARAMS.TAB] });
  const [currentTab, setCurrentTab] = useState(
    customQueryParams.values[DASHBOARD_QUERY_PARAMS.TAB]
      ? customQueryParams.values[DASHBOARD_QUERY_PARAMS.TAB]
      : DASHBOARD_TABS.ALL,
  );

  useEffect(() => {
    if (!isEmpty(companyConnection?.trialEndedProductsMessages)) {
      const product = Object.entries(
        companyConnection?.trialEndedProductsMessages as Record<ProductName, TrialEndedProduct>,
      )
        ?.map(([name, item]) => ({
          name: name as ProductName,
          ...item,
        }))
        ?.filter((item: TrialEndedProduct) => !item.read)[0];

      if (product) {
        setTrialEndedProduct(product);
        setIsTrialEndedModalVisible(true);
      }
    }
  }, [companyConnection?.trialEndedProductsMessages, i18n]);

  const onCloseTrialEndedModal = async () => {
    setIsTrialEndedModalVisible(false);

    if (trialEndedProduct) {
      await updateTrialEndedProductsMessages({
        [trialEndedProduct.name]: {
          read: true,
        },
      });
    }
  };

  const onSubmitTrialEndedModal = () => {
    // @ts-ignore
    history.push(routes.SETTINGS_MODULES.build({ role: ROLES.ADMIN }));
  };

  const TABS = [
    {
      label: (i18n: I18n) => i18n._(t`All`),
      key: DASHBOARD_TABS.ALL,
    },
    {
      label: (i18n: I18n) => i18n._(t`As employee`),
      key: DASHBOARD_TABS.AS_EMPLOYEE,
    },
    {
      label: (i18n: I18n) => i18n._(t`As coach`),
      key: DASHBOARD_TABS.AS_COACH,
    },
  ];

  return (
    <Wrapper>
      <Header user={user} />
      <BaseLayout maxWidth="1001px">
        <DashboardWrapper>
          <LeftColumn>
            <Title>{i18n._(t`Tasks`)}</Title>
            <SectionWrapper>
              {user.isCoach || user.isAdmin ? (
                <>
                  <HeaderWrapper>
                    <HeaderTabs
                      tabs={TABS}
                      selectedTab={currentTab}
                      handleChangeTab={(key) => setCurrentTab(key as DASHBOARD_TABS)}
                    />
                  </HeaderWrapper>
                  {currentTab === DASHBOARD_TABS.ALL && (
                    <TasksSection type={TASK_FILTER_BY_USER_TYPE.ALL} />
                  )}
                  {currentTab === DASHBOARD_TABS.AS_EMPLOYEE && (
                    <TasksSection type={TASK_FILTER_BY_USER_TYPE.EMPLOYEE} />
                  )}
                  {currentTab === DASHBOARD_TABS.AS_COACH && (
                    <TasksSection type={TASK_FILTER_BY_USER_TYPE.COACH} />
                  )}
                </>
              ) : (
                <TasksSection type={TASK_FILTER_BY_USER_TYPE.EMPLOYEE} isTab={false} />
              )}
            </SectionWrapper>
          </LeftColumn>
          <RightColumn>
            <UserDashboardSidebar
              isModuleJobMatrixEnabled={isModuleJobMatrixEnabled}
              isModuleReviewsEnabled={isModuleReviewsEnabled}
              isModuleMeetingsEnabled={isModuleMeetingsEnabled}
            />
          </RightColumn>
          {user && !isEmpty(user) && !user.completedFirstTimeSetup && <FirstTimeSetupModalNew />}
        </DashboardWrapper>
      </BaseLayout>
      {isTrialEndedModalVisible && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.INFO}
          title={i18n._(t`Free trial ended`)}
          description={i18n._(
            t`The ${getProductName(
              trialEndedProduct?.name as ProductName,
            )} product is not visible for you and all members within your company since ${moment(
              trialEndedProduct?.dateTime,
            ).format('ll')}.`,
          )}
          cancelButton={i18n._(t`Close`)}
          submitButton={i18n._(t`View plan`)}
          onClose={onCloseTrialEndedModal}
          onSubmit={onSubmitTrialEndedModal}
        />
      )}
    </Wrapper>
  );
};

export default UserDashboard;
