import React, { memo } from 'react';

import { TASK_STATUS } from '@learned/constants';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';

import { Button, ButtonVariant } from '~/components/Buttons';

import { Actions, Card, Container, Image, Subtitle, Title } from './InputNoLongerPossible.design';

import CompletedPlaceholder from '~/assets/3.0/placeholders/completed-state-round.png';
import TrafficCone from '~/assets/3.0/placeholders/traffic-cone.png';

import { DASHBOARD } from '~/constants/routes/dashboards';

type InputPlaceholder = {
  title: string;
  subtitle: string;
  onPressAction?(): void;
  placeholderImage?: React.ReactNode;
};

// TODO: Add more status placeholders when needed
const INVALID_INPUT_ITEMS: {
  [TASK_STATUS.EXPIRED]: InputPlaceholder;
  [TASK_STATUS.COMPLETED]: InputPlaceholder;
} = {
  [TASK_STATUS.EXPIRED]: {
    title: i18n._(t`Input is no longer possible`),
    subtitle:
      i18n._(t`This review request is canceled, rejected, or expired. If you need further assistance,
            please contact the sender.`),
    onPressAction: () => DASHBOARD.go(),
    placeholderImage: <Image src={TrafficCone} height={191} width={173.3} />,
  },
  [TASK_STATUS.COMPLETED]: {
    title: i18n._(t`Already completed!`),
    subtitle: i18n._(t`You have already completed this task.`),
    onPressAction: () => DASHBOARD.go(),
    placeholderImage: <Image src={CompletedPlaceholder} height={152} width={169} />,
  },
};

const getInvalidInputPlaceholder = (status?: TASK_STATUS): InputPlaceholder => {
  switch (status) {
    case TASK_STATUS.COMPLETED: {
      return INVALID_INPUT_ITEMS[TASK_STATUS.COMPLETED];
    }
    default: {
      return INVALID_INPUT_ITEMS[TASK_STATUS.EXPIRED];
    }
  }
};

type InputNoLongerPossibleProps = {
  isActionButton?: boolean;
  status?: TASK_STATUS;
};

const InputNoLongerPossible = memo(
  ({ isActionButton = true, status }: InputNoLongerPossibleProps) => {
    const { title, subtitle, onPressAction, placeholderImage } = getInvalidInputPlaceholder(status);

    return (
      <Container>
        <Card>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          {placeholderImage && placeholderImage}
          {isActionButton && (
            <Actions>
              <Button
                onClick={onPressAction}
                variant={ButtonVariant.PRIMARY}
                label={<Trans>Go to home</Trans>}
              />
            </Actions>
          )}
        </Card>
      </Container>
    );
  },
);

export { InputNoLongerPossible };
